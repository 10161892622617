<div id="page">
  <h1 class="mat-h1">{{verb}} User</h1>
  <form #userForm="ngForm" *ngIf="editMode !== true || editMode == true && (userLoad | async) == true">
    <mat-form-field fxFlex="1 1 100%" appearance="outline">
      <button mat-icon-button (click)="findADUser()">
        <mat-icon svgIcon="account-search"></mat-icon>
      </button>
    <input matInput placeholder="username" [(ngModel)]="formUser$.username"  name="username" [readonly]="editMode">

    <mat-label>Username</mat-label>
    <mat-hint align="start"><strong>first initial and last name (e.g. rdonahue)</strong> </mat-hint>
    <mat-error *ngIf="userForm.hasError('username') && !userForm.hasError('username')">
      Please enter a valid username
    </mat-error>
    <mat-error *ngIf="userForm.hasError('required')">
      username is <strong>required</strong>
    </mat-error>
  </mat-form-field>

    <br/>

    <div class="flex-row">
      <mat-form-field fxFlex="0 1 98%" appearance="outline">
        <mat-label>Custodial Organization Name</mat-label>
        <mat-select name="custodialOrganizationId" [(ngModel)]="formUser$.custodialOrganizationId">
          <mat-option [value]="0" disabled="true">Undetermined</mat-option>
          <mat-option [value]="3">Patient Engagement Advisors</mat-option>
          <mat-option [value]="1">AdventHealth Pharmacy Sebring</mat-option>
          <mat-option [value]="2">Walgreens</mat-option>
          <mat-option [value]="4">Bittings Pharmacy</mat-option>
          <mat-option [value]="5">AdventHealth Pharmacy Shawnee Mission</mat-option>
        </mat-select>
        <mat-hint align="start">What organization does the user work for?</mat-hint>
      </mat-form-field>
    </div>

    <div class="flex-row">
      <mat-form-field fxFlex="0 1 48%" appearance="outline">
        <mat-label>First Name</mat-label>
        <input matInput placeholder="first name" [(ngModel)]="formUser$.firstName" (ngModelChange)="updateName()"
               name="firstName"/>
        <mat-hint align="start">The user's first name.</mat-hint>
      </mat-form-field>

      <mat-form-field fxFlex="0 1 48%" appearance="outline">
        <mat-label>Last Name</mat-label>
        <input matInput placeholder="last name" [(ngModel)]="formUser$.lastName" (ngModelChange)="updateName()"
               name="lastName"/>
        <mat-hint align="start">The user's last name.</mat-hint>
      </mat-form-field>

    </div>

    <br/>

    <mat-form-field fxFlex="1 1 100%" appearance="outline">
      <mat-label>The user's full name.</mat-label>
      <input matInput placeholder="full name" [ngModel]="formUser$.name" readonly name="name"/>
      <mat-hint align="start">The user's full name (readonly).</mat-hint>
    </mat-form-field>

    <br/>
    <div class="flex-row">
      <mat-form-field fxFlex="0 1 48%" appearance="outline">
    <mat-label>Email Address</mat-label>
    <input matInput placeholder="Email" [(ngModel)]="formUser$.email" name="email"/>
    <mat-hint align="start">The user's email address.</mat-hint>
  </mat-form-field>
      <mat-form-field fxFlex="0 1 48%" appearance="outline">
    <mat-label>Phone Number (Text Messaging Required)</mat-label>
    <input matInput placeholder="+12345678900" type='tel' [(ngModel)]="formUser$.phoneNumber" name="phoneNumber"/>
    <mat-hint align="start">The user's phone number (formatted +11231231234).</mat-hint>
  </mat-form-field>
    </div>
  <br />
  <h3 class="mat-h3">User Locations <button mat-raised-button (click)="addAllLocations()">Add All Locations</button> <button mat-raised-button color="warn" (click)="clearLocations()">Clear Locations</button></h3>
  <mat-form-field class="full-width" appearance="outline">
    <mat-chip-list #locationList>
      <mat-chip
        *ngFor="let location of locations"
        [selectable]="selectable"
        [removable]="removable"
        color="accent"
        (removed)="removeLocation(location)">
        {{location.name}}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>
      <input
        placeholder="New location..."
        #locationInput
        #trigger2="matAutocompleteTrigger"
        [formControl]="locationControl"
        [matAutocomplete]="location"
        [matChipInputFor]="locationList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="addOnBlur"
        (matChipInputTokenEnd)="addLocation($event)">
    </mat-chip-list>
    <mat-autocomplete #location="matAutocomplete" (optionSelected)="selectedLocation($event)">
      <mat-option *ngFor="let location of filteredLocations | async" [value]="location"
                  (click)="$event.stopPropagation(); trigger2.openPanel()">
        {{location.name}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
    <br/>
  <h3 class="mat-h3">User Roles</h3>
  <mat-form-field class="full-width" appearance="outline">
    <mat-chip-list #roleList>
      <mat-chip
        *ngFor="let role of roles"
        [selectable]="selectable"
        [removable]="removable"
        color="accent"
        (removed)="removeRole(role)">
        {{role.name}}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>
      <input
        placeholder="New role..."
        #roleInput
        #trigger="matAutocompleteTrigger"
        [formControl]="roleControl"
        [matAutocomplete]="role"
        [matChipInputFor]="roleList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="addOnBlur"
        (matChipInputTokenEnd)="addRole($event)">
    </mat-chip-list>
    <mat-autocomplete #role="matAutocomplete" (optionSelected)="selectedRole($event)">
      <mat-option *ngFor="let role of filteredRoles | async" [value]="role"
                  (click)="$event.stopPropagation(); trigger.openPanel()">
        {{role.name}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <div class="form-actions">
    <button type="button" mat-raised-button color="primary"  (click)="saveUser(userForm)" cdkFocusInitial [disabled]="!userForm.valid" >{{saveLabel}}</button>
    <button type="button" mat-raised-button [routerLink]="['/admin/users/list']">{{cancelLabel}}</button>
  </div>

</form>
  <br/><br/>
</div>
